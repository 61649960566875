import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Remarkable } from "remarkable";

const AboutServices = ({ sect }) => {
  const [md, setMd] = useState(new Remarkable());
  const renderedContent = md.render(sect.descirptionLink || "");
  console.log("sect", sect);
  return (
    <div className="rn-columns-area ptb--100 bg_color--1">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center service-style--3 mb--30">
              <ScrollAnimation animateIn="fadeIn">
                <h2 className="title">{sect.title}</h2>

                <div dangerouslySetInnerHTML={{ __html: renderedContent }} />
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="row">
          {sect.serviceCard.map((val, i) => {
            const customColors = ["0", "1", "2", "3", "4", "5"];
            const customColorClass = customColors[i % customColors.length];
            return (
              <div className="col-lg-4 col-md-6 col-12 mt--20 d-flex align-items-stretch">
                <div
                  className={`single-column custom-color custom-color--${customColorClass} mt_sm--30 mt_md--30`}
                >
                  <ScrollAnimation animateIn="fadeIn">
                    <div
                      className="icon"
                      style={{ paddingBottom: "20px", textAlign: "center" }}
                    >
                      <img
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}` +
                          val?.img.data.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                    <h4 className="tilte" style={{ textAlign: "center" }}>
                      {val.title}
                    </h4>
                    <p style={{ textAlign: "center" }}>{val.description}</p>
                  </ScrollAnimation>
                </div>
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="view-more-btn mt--80 text-center">
              <HashLink
                className="rn-button-style--20 btn-solid"
                to="/contact-us/#contact-page-form"
              >
                <span>{sect.ctaButtontext}</span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutServices;
