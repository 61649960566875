import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import LazyLoad from "react-lazyload";

const OurClients = ({ sect }) => {
  const [data, setData] = useState([]);
  const clientsRef = useRef(null); // Reference to the clients container

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchClientsData();
          observer.unobserve(entry.target);
        }
      });
    });

    if (clientsRef.current) {
      observer.observe(clientsRef.current);
    }

    return () => {
      if (clientsRef.current) {
        observer.unobserve(clientsRef.current);
      }
    };
  }, []);

  const fetchClientsData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/clients/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  };

  return (
    <div ref={clientsRef}>
      <LazyLoad height={200} once>
        <div
          className="rn-brand-area brand-separation pb--90 pt--90"
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BACKEND_URL +
              sect?.bgImg?.data?.attributes?.url
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--15">
                  <h2 className="title" style={{ fontSize: "40px" }}>
                    {sect.title}
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul className="brand-style-2">
                  {data.map((val, ind) =>
                    val.attributes.img.data.map((value, index) => (
                      <li key={index}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${value.attributes.url}`}
                          alt="linnworks"
                        />
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </div>
  );
};

export default OurClients;
