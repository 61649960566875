import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import Testimonials from "../global/Testimonials";
import { HashLink } from "react-router-hash-link";
import LazyLoad from "react-lazyload";

const AllPortfolio = ({ sect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <div className="portfolio-area pt--90 pb--50 bg_color--1">
        <div className="rn-slick-dot">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="slick-space-gutter--15 slickdot--20">
                  <Slider {...settings} {...slickDot}>
                    {data.map((value, index) => (
                      <Link
                        to={{
                          pathname: `/portfolio-details/${value.attributes.slug}`,
                          state: {
                            projectTitle: value.attributes.projectTitle,
                            projectDes: value.attributes.projectDes,
                            platform: value.attributes.platform,
                            projectDuration: value.attributes.projectDuration,
                            websiteUrl: value.attributes.websiteUrl,
                            imgUrl:
                              value.attributes.portfolioImg.data.attributes.url,
                            headerImg:
                              value.attributes.headerImg.data.attributes.url,
                            name: value.attributes.name,
                          },
                        }}
                      >
                        <div className="portfolio" key={index}>
                          <div className="thumbnail-inner">
                            <Link
                              to={{
                                pathname: `/portfolio-details/${value.attributes.slug}`,
                                state: {
                                  projectTitle: value.attributes.projectTitle,
                                  projectDes: value.attributes.projectDes,
                                  platform: value.attributes.platform,
                                  projectDuration:
                                    value.attributes.projectDuration,
                                  websiteUrl: value.attributes.websiteUrl,
                                  imgUrl:
                                    value.attributes.portfolioImg.data
                                      .attributes.url,
                                  headerImg:
                                    value.attributes.headerImg.data.attributes
                                      .url,
                                  name: value.attributes.name,
                                },
                              }}
                            >
                              <LazyLoad height={200} offset={100}>
                                <div
                                  className="thumbnail"
                                  style={{
                                    backgroundImage: `url(${
                                      process.env.REACT_APP_BACKEND_URL +
                                      value.attributes.Img.data[0].attributes
                                        .url
                                    })`,
                                  }}
                                ></div>
                              </LazyLoad>
                            </Link>
                            <Link
                              to={{
                                pathname: `/portfolio-details/${value.attributes.slug}`,
                                state: {
                                  projectTitle: value.attributes.projectTitle,
                                  projectDes: value.attributes.projectDes,
                                  platform: value.attributes.platform,
                                  projectDuration:
                                    value.attributes.projectDuration,
                                  websiteUrl: value.attributes.websiteUrl,
                                  imgUrl:
                                    value.attributes.portfolioImg.data
                                      .attributes.url,
                                  headerImg:
                                    value.attributes.headerImg.data.attributes
                                      .url,
                                  name: value.attributes.name,
                                },
                              }}
                            >
                              <div
                                className={`bg-blr-image ${value.image}`}
                              ></div>
                            </Link>
                          </div>
                          <div className="content">
                            <div className="inner">
                              <p
                                style={{
                                  fontWeight: "600",
                                  textAlign: "center",
                                }}
                              >
                                {" "}
                                <Link
                                  to={{
                                    pathname: `/portfolio-details/${value.attributes.slug}`,
                                    state: {
                                      projectTitle:
                                        value.attributes.projectTitle,
                                      projectDes: value.attributes.projectDes,
                                      platform: value.attributes.platform,
                                      projectDuration:
                                        value.attributes.projectDuration,
                                      websiteUrl: value.attributes.websiteUrl,
                                      imgUrl:
                                        value.attributes.portfolioImg.data
                                          .attributes.url,
                                      headerImg:
                                        value.attributes.headerImg.data
                                          .attributes.url,
                                      name: value.attributes.name,
                                    },
                                  }}
                                >
                                  {value.attributes.category}
                                </Link>
                              </p>
                              <h5 style={{ textAlign: "center" }}>
                                <Link
                                  to={{
                                    pathname: `/portfolio-details/${value.attributes.slug}`,
                                    state: {
                                      projectTitle:
                                        value.attributes.projectTitle,
                                      projectDes: value.attributes.projectDes,
                                      platform: value.attributes.platform,
                                      projectDuration:
                                        value.attributes.projectDuration,
                                      websiteUrl: value.attributes.websiteUrl,
                                      imgUrl:
                                        value.attributes.portfolioImg.data
                                          .attributes.url,
                                      headerImg:
                                        value.attributes.headerImg.data
                                          .attributes.url,
                                      name: value.attributes.name,
                                    },
                                  }}
                                >
                                  {value.attributes.title}
                                </Link>
                              </h5>

                              <div
                                className="portfolio-button"
                                style={{ textAlign: "center" }}
                              >
                                <Link
                                  className="rn-btn"
                                  to={{
                                    pathname: `/portfolio-details/${value.attributes.slug}`,
                                    state: {
                                      projectTitle:
                                        value.attributes.projectTitle,
                                      projectDes: value.attributes.projectDes,
                                      platform: value.attributes.platform,
                                      projectDuration:
                                        value.attributes.projectDuration,
                                      websiteUrl: value.attributes.websiteUrl,
                                      imgUrl:
                                        value.attributes.portfolioImg.data
                                          .attributes.url,
                                      headerImg:
                                        value.attributes.headerImg.data
                                          .attributes.url,
                                      name: value.attributes.name,
                                    },
                                  }}
                                >
                                  View Details
                                </Link>
                              </div>
                            </div>
                          </div>
                          <Link
                            className="link-overlay"
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          ></Link>
                        </div>
                      </Link>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio-area pt--120 pb--20 bg_image bg_image--3">
        <div className="portfolio-sacousel-inner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--15">
                  <h2 className="title">{sect.title}</h2>
                  <p>{sect.description}</p>
                </div>
              </div>
            </div>
            <div className="row">
              {data.map((value, index) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={index}>
                  <Link
                    to={{
                      pathname: `/portfolio-details/${value.attributes.slug}`,
                      state: {
                        projectTitle: value.attributes.projectTitle,
                        projectDes: value.attributes.projectDes,
                        platform: value.attributes.platform,
                        projectDuration: value.attributes.projectDuration,
                        websiteUrl: value.attributes.websiteUrl,
                        imgUrl:
                          value.attributes.portfolioImg.data.attributes.url,
                        headerImg:
                          value.attributes.headerImg.data.attributes.url,
                        name: value.attributes.name,
                      },
                    }}
                  >
                    <div className="portfolio text-center mt--40">
                      <div className="thumbnail-inner">
                        <LazyLoad height={200} offset={100}>
                          <div
                            className="thumbnail"
                            style={{
                              backgroundImage: `url(${
                                process.env.REACT_APP_BACKEND_URL +
                                value.attributes.Img.data[0].attributes.url
                              })`,
                            }}
                          ></div>
                        </LazyLoad>

                        <div className={`bg-blr-image ${value.image}`}></div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p style={{ fontWeight: "600" }}>
                            {value.attributes.category}
                          </p>
                          <h4>
                            <Link
                              to={{
                                pathname: `/portfolio-details/${value.attributes.slug}`,
                                state: {
                                  projectTitle: value.attributes.projectTitle,
                                  projectDes: value.attributes.projectDes,
                                  platform: value.attributes.platform,
                                  projectDuration:
                                    value.attributes.projectDuration,
                                  websiteUrl: value.attributes.websiteUrl,
                                  imgUrl:
                                    value.attributes.portfolioImg.data
                                      .attributes.url,
                                  headerImg:
                                    value.attributes.headerImg.data.attributes
                                      .url,
                                  name: value.attributes.name,
                                },
                              }}
                            >
                              {value.attributes.title}
                            </Link>
                          </h4>

                          <div className="portfolio-button">
                            <Link
                              className="rn-btn"
                              to={{
                                pathname: `/portfolio-details/${value.attributes.slug}`,
                                state: {
                                  projectTitle: value.attributes.projectTitle,
                                  projectDes: value.attributes.projectDes,
                                  platform: value.attributes.platform,
                                  projectDuration:
                                    value.attributes.projectDuration,
                                  websiteUrl: value.attributes.websiteUrl,
                                  imgUrl:
                                    value.attributes.portfolioImg.data
                                      .attributes.url,
                                  headerImg:
                                    value.attributes.headerImg.data.attributes
                                      .url,
                                  name: value.attributes.name,
                                },
                              }}
                            >
                              View Details
                            </Link>
                          </div>
                        </div>
                      </div>
                      <Link
                        className="link-overlay"
                        to={{
                          pathname: `/portfolio-details/${value.attributes.slug}`,
                          state: {
                            projectTitle: value.attributes.projectTitle,
                            projectDes: value.attributes.projectDes,
                            platform: value.attributes.platform,
                            projectDuration: value.attributes.projectDuration,
                            websiteUrl: value.attributes.websiteUrl,
                            imgUrl:
                              value.attributes.portfolioImg.data.attributes.url,
                            headerImg:
                              value.attributes.headerImg.data.attributes.url,
                            name: value.attributes.name,
                          },
                        }}
                      ></Link>
                    </div>
                  </Link>
                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--90 text-center">
                  <HashLink
                    className="rn-button-style--2 btn-solid"
                    to="/contact-us/#contact-page-form"
                  >
                    <span>Connect With Us Today</span>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Testimonials />
      </div>
    </>
  );
};

export default AllPortfolio;
