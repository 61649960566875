import React from "react";
import ServicesStyle2 from "./ServicesStyle2";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const ServicesSec = ({ sect }) => {
  return (
    <>
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row sercice-details-content  ">
                    <div className="col-lg-6 col-12">
                      <div className="thumb">
                        <img
                          className="w-100"
                          src="/assets/images/service/service_details_img.jpg"
                          alt="Service Images"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <p>{sect.lHeading}</p>
                        <p>{sect.lDec1}</p>
                        <p>{sect.lDec2}</p>
                        <h4 className="title"></h4>
                        <div className="rn-form-group  d-flex align-items-center justify-content-center">
                          <HashLink to="/contact-us/#contact-page-form">
                            <button
                              className="rn-button-style--2 btn-solid"
                              type="submit"
                              value="submit"
                              name="submit"
                              id="mc-embedded-subscribe"
                            >
                              GET IN TOUCH
                            </button>
                          </HashLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesSec;
