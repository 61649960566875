import React from "react";
import Slider from "react-slick";
import { testimonialActivation } from "../page-demo/script";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { FaStar } from "react-icons/fa";
import "./NarrowClutch.scss";
import LazyLoad from "react-lazyload";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "red", right: "-15px" }}
      onClick={onClick}
    >
      <IoIosArrowForward size={32} />
    </div>
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "red" }}
      onClick={onClick}
    >
      <IoIosArrowBack size={32} />
    </div>
  );
};

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  //slidesToShow: 1,
  slidesToScroll: 3,
  autoplay: true,
  autoplaySpeed: 3000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 760, // Mobile breakpoint
      settings: {
        slidesToScroll: 1, // Set slidesToScroll to 1 for mobile view
        slidesToShow: 1,
      },
    },
  ],
};

const NarrowClutch = React.memo(() => {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="cluthtwo testimonial-area  bg_color--5 pt_md--80 pb_md--110 pt_sm--40 pb_sm--110 ">
          <div className="conatainer align">
            <div className="row align-items-center">
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="heading">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://clutch.co/profile/omtech?utm_source=widget&amp;utm_medium=12&amp;utm_campaign=widget&amp;utm_content=title&amp;utm_term=localhost"
                  >
                    OMtech Reviews
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="d-flex align-items-baseline justify-content-center ">
                  <div className="rating">5.0 &nbsp;</div>
                  <div className="star-clutch">
                    <a
                      href="https://clutch.co/profile/omtech?utm_source=widget&amp;utm_medium=12&amp;utm_campaign=widget&amp;utm_content=stars&amp;utm_term=localhost#reviews"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                      <FaStar />
                    </a>
                  </div>
                  <div className="reviewnumb">
                    <a
                      href="https://clutch.co/profile/omtech?utm_source=widget&amp;utm_medium=12&amp;utm_campaign=widget&amp;utm_content=num_reviews&amp;utm_term=localhost#reviews"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      15 reviews
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-3">
              <div>15 Reviews</div>
            </div> */}
              <div className="col-lg-3 d-flex justify-content-center">
                <div className="logotype">
                  <a
                    className="logotype"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://clutch.co/profile/omtech?utm_source=widget&amp;utm_medium=3&amp;utm_campaign=widget&amp;utm_content=stars&amp;utm_term=localhost#logo"
                  >
                    Powered by
                    <img src="/assets/images/clutch/photo.png" alt="clutch" />
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-1 d-flex justify-content-end">
              <img src="/assets/images/clutch/photo.png" alt="clutch" />
            </div> */}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Slider
                  className="mt--10 mt_sm--5 mt_md--5 testimonial-activation rn-slick-dot slick-gutter-15"
                  {...testimonialActivation}
                  {...settings}
                  nextArrow={<SampleNextArrow />}
                  prevArrow={<SamplePrevArrow />}
                >
                  {/*1 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>"They were great to deal with "</p>
                          </div>
                          <p>Web Design, Web Design Company</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*2 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "OMtech was very capable, offering guidance and
                              great communication throughout the project"
                            </p>
                          </div>
                          <p>Owner, mystead (the place where one is)</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*3 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "The speed of the site and the ease of us were
                              dramatically improved"
                            </p>
                          </div>
                          <p>Systems Admin, Dina International Ltd</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*4 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "Their prompt replies and timely solutions were
                              impressive"
                            </p>
                          </div>
                          <p>Manager, Dina International Ltd</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*5 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>"They were responsive and cost-effective."</p>
                          </div>
                          <p>Director, Classy Nails</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*6 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "The developer was very accommodating and
                              professional throughout"
                            </p>
                          </div>
                          <p>Account Manager, Design Studio</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*7 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>"They were great to work with"</p>
                          </div>
                          <p>Marketing Director, Croma Ltd</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*8 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "They've provided very good communication and
                              raised questions ASAP."
                            </p>
                          </div>
                          <p>IT Consultant, Manufacturing Company</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*9 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "The vendor has experience in PHP and WordPress'
                              Gravity Forms"
                            </p>
                          </div>
                          <p>IT Consultant, Roadgear</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*10 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "OMtech could literally come up with solutions for
                              all kinds of issues!"
                            </p>
                          </div>
                          <p>Director, Subscription Company</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*11 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>
                              "They boast excellent service through good
                              communication and quick turnarounds"
                            </p>
                          </div>
                          <p>Manager, Motorcycle Parts Supplier</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}

                  {/*12 Start Single Testimonial  */}
                  <a
                    /* target="_blank" */
                    rel="noopener noreferrer"
                    href={`/clutch-review/`}
                  >
                    <div className="single-testimonial">
                      <div className="pv-testimonial col-lg-12">
                        <div className="inner">
                          <div className="inner-top">
                            <div className="info d-flex align-items-center ">
                              <div className="title ">
                                <span className="rating">5.0</span>
                                <span className="star">
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                  <FaStar />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="content">
                            <p>"They provided really terrific services."</p>
                          </div>
                          <p>Director, The Pond People Ltd</p>
                          <div className="verified">Verified Review</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  {/* End Single Testimonial  */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
});

export default NarrowClutch;
