import React, { useState, useEffect } from "react";
import axios from "axios";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const SrvcSec4 = ({ sect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/pwdss/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  return (
    <>
      <div className="service-area pt--120 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="section-title text-center service-style--3 mb--30"
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                data-aos-delay="300"
              >
                <h2 className="title">{sect.title}</h2>
                <p>{sect.description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((val, i) => {
              const customColors = ["1", "2", "3", "4", "5", "6"];
              const customColorClass = customColors[i % customColors.length];
              return (
                <div
                  className={`col-lg-4 col-md-6 col-sm-6 col-12 pt--40 text-center  d-flex align-items-stretch`}
                  key={i}
                >
                  <Link to={val.attributes?.link}>
                    <div
                      data-aos="zoom-in"
                      data-aos-anchor-placement="top-bottom"
                      data-aos-delay="300"
                      className={`singleTwo-column custom-color custom-color--${customColorClass} service service__style--2 `}
                    >
                      <div className="icon">
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}` +
                            val?.attributes?.img.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="content-pwds">
                        <h3 className="title">{val.attributes.title}</h3>
                        <p>{val.attributes.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SrvcSec4;
