import React from 'react'

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";


const ScrollTop = ({sect}) => {
    const style = {
        position: 'fixed',
        bottom: '20px',
        right: 'calc(50% + 1400px)',
        zIndex: '1000' // Adjust as needed
    };
    return (
        <div className="backto-top" >
            <ScrollToTop showUnder={sect.showUnder}>
                <FiChevronUp />
            </ScrollToTop>
        </div>
    )
}

export default ScrollTop