import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

const Portfolio = ({ sect }) => {
  const [data, setData] = useState([]);
  const portfolioRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          fetchPortfolioData();
          observer.unobserve(entry.target);
        }
      });
    });

    if (portfolioRef.current) {
      observer.observe(portfolioRef.current);
    }

    return () => {
      if (portfolioRef.current) {
        observer.unobserve(portfolioRef.current);
      }
    };
  }, []);

  const fetchPortfolioData = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  };

  return (
    <div ref={portfolioRef}>
      <LazyLoad height={200} once>
        <div className="portfolio-area  bg_image ">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--15 pt_md--60">
                    <h2 className="title">{sect.title}</h2>
                    <p>{sect.description}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                {data.slice(0, 6).map((value, index) => (
                  <div
                    className="col-lg-6 col-md-6 col-sm-6 col-12"
                    key={index}
                  >
                    <Link
                      to={{
                        pathname: `/portfolio-details/${value.attributes.slug}`,
                        state: {
                          projectTitle: value.attributes.projectTitle,
                          projectDes: value.attributes.projectDes,
                          platform: value.attributes.platform,
                          projectDuration: value.attributes.projectDuration,
                          websiteUrl: value.attributes.websiteUrl,
                          imgUrl:
                            value?.attributes?.portfolioImg?.data?.attributes
                              ?.url,
                          headerImg:
                            value.attributes.headerImg.data.attributes.url,
                          name: value.attributes.name,
                        },
                      }}
                    >
                      <div className="portfolio text-center mt--40">
                        <div className="thumbnail-inner">
                          <LazyLoad height={200} offset={100}>
                            <div
                              className="thumbnail"
                              style={{
                                backgroundImage: `url(${
                                  process.env.REACT_APP_BACKEND_URL +
                                  value.attributes.Img.data[0].attributes.url
                                })`,
                              }}
                            ></div>
                          </LazyLoad>

                          <div className={`bg-blr-image ${value.image}`}></div>
                        </div>
                        <div className="content">
                          <div className="inner">
                            {
                              <p style={{ fontWeight: "600" }}>
                                {value.attributes.category}
                              </p>
                            }
                            <h4>
                              <Link
                                to={{
                                  pathname: `/portfolio-details/${value.attributes.slug}`,
                                  state: {
                                    projectTitle: value.attributes.projectTitle,
                                    projectDes: value.attributes.projectDes,
                                    platform: value.attributes.platform,
                                    projectDuration:
                                      value.attributes.projectDuration,
                                    websiteUrl: value.attributes.websiteUrl,
                                    imgUrl:
                                      value?.attributes?.portfolioImg?.data
                                        ?.attributes?.url,
                                    headerImg:
                                      value.attributes.headerImg.data.attributes
                                        .url,
                                    name: value.attributes.name,
                                  },
                                }}
                              >
                                {value.attributes.title}
                              </Link>
                            </h4>

                            <div className="portfolio-button">
                              <Link
                                className="rn-btn"
                                to={{
                                  pathname: `/portfolio-details/${value.attributes.slug}`,
                                  state: {
                                    projectTitle: value.attributes.projectTitle,
                                    projectDes: value.attributes.projectDes,
                                    platform: value.attributes.platform,
                                    projectDuration:
                                      value.attributes.projectDuration,
                                    websiteUrl: value.attributes.websiteUrl,
                                    imgUrl:
                                      value?.attributes?.portfolioImg?.data
                                        ?.attributes?.url,
                                    headerImg:
                                      value.attributes.headerImg.data.attributes
                                        .url,
                                    name: value.attributes.name,
                                  },
                                }}
                              >
                                View Details
                              </Link>
                            </div>
                          </div>
                        </div>
                        <Link
                          className="link-overlay"
                          to={{
                            pathname: `/portfolio-details/${value.attributes.slug}`,
                            state: {
                              projectTitle: value.attributes.projectTitle,
                              projectDes: value.attributes.projectDes,
                              platform: value.attributes.platform,
                              projectDuration: value.attributes.projectDuration,
                              websiteUrl: value.attributes.websiteUrl,
                              imgUrl:
                                value?.attributes?.portfolioImg?.data
                                  ?.attributes?.url,
                              headerImg:
                                value.attributes.headerImg.data.attributes.url,
                              name: value.attributes.name,
                            },
                          }}
                        ></Link>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="view-more-btn mt--90 text-center">
                    <Link
                      className="rn-button-style--2 btn-solid"
                      to="/portfolio/"
                    >
                      <span>{sect.buttonTxt}</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </div>
  );
};

export default Portfolio;
