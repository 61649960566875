import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";

const Header = ({ logo, color = "default-color" }) => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false); // State for the Services submenu
  const [isBespokeSubmenuOpen, setIsBespokeSubmenuOpen] = useState(false); // State for the Bespoke Web Development submenu
  const [isCMSSubmenuOpen, setIsCMSSubmenuOpen] = useState(false); // State for the Bespoke Web Development submenu

  

  const ref = useRef(null);

  const handleServicesDropdownToggle = (e, type) => {
    if (type === "services") {
      history.push("/services/");
      setOpen(false); // Close the menu
    } else {
      setIsServicesDropdownOpen(!isServicesDropdownOpen); // Toggle the submenu
    }
  };

  const handleBespokeSubmenuToggle = (e, type) => {
    setIsBespokeSubmenuOpen(!isBespokeSubmenuOpen);
    if (type === "bespoke") {
      setIsBespokeSubmenuOpen(false);
      setIsServicesDropdownOpen(false);
      history.push("/bespoke-web-development/");
      setOpen(false);
    }
  };

  const handleCMSSubmenuToggle = (e, type) => {
    setIsCMSSubmenuOpen(!isCMSSubmenuOpen);
    if (type === "cms") {
      setIsCMSSubmenuOpen(false);
      setIsServicesDropdownOpen(false);
      history.push("/cms-development/");
      setOpen(false);
    }
  };

  const closeDropdownMenu = () => {
    setOpen(false);
    setIsBespokeSubmenuOpen(false);
    setIsServicesDropdownOpen(false);
    setIsCMSSubmenuOpen(false);
  };

  const handleClickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      setOpen(false);
      setIsBespokeSubmenuOpen(false);
      setIsServicesDropdownOpen(false);
      setIsCMSSubmenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (!open) {
      document.querySelector(".header-wrapper").classList.remove("menu-open");
    }
  }, [open]);

  

  useEffect(() => {
    if (!open) {
      document.querySelector(".header-wrapper").classList.remove("menu-open");
    }
  }, [open]);

  /* useEffect(() => {
    setTimeout(() => {
      setLogoLoaded(true);
    }, 2000);
  }, []); */

  const menuTrigger = () => {
    setOpen(!open);
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };

  const closeMenuTrigger = () => {
    setOpen(false);
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };
  let logoUrl;

  logoUrl = <img src="/assets/images/logo.png" alt="Digital Agency" />;


  useEffect(() => {
    window.addEventListener("scroll", () => {
      const value = window.scrollY;
      const headerFixed = document.querySelector(".header--fixed");
      if (value > 50) {
        headerFixed.classList.add("sticky");
      } else {
        headerFixed.classList.remove("sticky");
      }
    });
    const elements = document.querySelectorAll(".has-droupdown > a");
    elements.forEach((element) => {
      element.onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    });

    return () => {
      window.removeEventListener("scroll", () => {});
      elements.forEach((element) => {
        element.onclick = null;
      });
    };
  }, []);
  return (
    <header
      className={`header-area formobile-menu header--fixed default-color `}
    >
      <div className="header-wrapper" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            { <Link to="/">{logoUrl}</Link>}
          </div>
        </div>
        <div className="mob-email ">
          <div>
            <a href="mailto:joe@omtechmasters.co.uk">
              <strong>joe@omtechmasters.co.uk</strong>
            </a>
          </div>
          <div className="info-call">
            <a href="tel:0116 393 0838">
              <strong>0116 393 0838</strong>
            </a>
          </div>
        </div>
        <div className="header-right laptopviewonly" ref={ref}>
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu ">
              <li>
                <Link to="/" onClick={() => closeMenuTrigger()}>
                  Home
                </Link>
              </li>

              <li
                className={`has-droupdown menucustom ${
                  isServicesDropdownOpen ? "open" : "has-droupdown"
                }`}
              >
                <span
                  className=""
                  onClick={(e) => handleServicesDropdownToggle(e, "services")}
                >
                  Services
                </span>
                <div
                  onClick={(e) => handleServicesDropdownToggle(e, "icon")}
                  className="arrowIcon"
                >
                  {isServicesDropdownOpen ? (
                    <span>&#9650;</span>
                  ) : (
                    <span>&#9660;</span>
                  )}
                </div>
                {isServicesDropdownOpen && (
                  <ul>
                    <li className="has-droupdown color-code">
                      <span
                        onClick={(e) =>
                          handleBespokeSubmenuToggle(e, "bespoke")
                        }
                      >
                        Bespoke Web Development
                      </span>
                      <div
                        onClick={(e) => handleBespokeSubmenuToggle(e, "icon")}
                        className="arrowIcon"
                      >
                        {isBespokeSubmenuOpen ? (
                          <span>&#9650;</span>
                        ) : (
                          <span>&#9660;</span>
                        )}
                      </div>
                      {isBespokeSubmenuOpen && (
                        <ul
                          className={`submenu ${
                            isBespokeSubmenuOpen ? "active" : ""
                          }`}
                        >
                          <ul>
                            <li>
                              <Link
                                className="color-code"
                                to="/web-app-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Web App Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/full-stack-web-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Full Stack Web Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/uk-website-company/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Uk Website Company
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/python-developers-uk/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Python Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/laravel-developers-uk/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Laravel Development
                              </Link>
                            </li>
                          </ul>
                        </ul>
                      )}
                    </li>
                    <li className="has-droupdown color-code">
                      <span onClick={(e) => handleCMSSubmenuToggle(e, "cms")}>
                        CMS Development
                      </span>
                      <div
                        onClick={(e) => handleCMSSubmenuToggle(e, "icon")}
                        className="arrowIcon"
                      >
                        {isCMSSubmenuOpen ? (
                          <span>&#9650;</span>
                        ) : (
                          <span>&#9660;</span>
                        )}
                      </div>
                      {isCMSSubmenuOpen && (
                        <ul
                          className={`submenu ${
                            isCMSSubmenuOpen ? "active" : ""
                          }`}
                        >
                          <ul>
                            <li>
                              <Link
                                to="/shopify-developers-uk/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Shopify Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/woocommerce-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Woocommerce Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/wordpress-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Wordpress Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/magento-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Magento Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/drupal-development/"
                                onClick={() => closeDropdownMenu()}
                              >
                                Drupal Development
                              </Link>
                            </li>
                          </ul>
                        </ul>
                      )}
                    </li>
                    <li>
                      <Link
                        className="red-color"
                        to="/web-development/"
                        onClick={() => closeDropdownMenu()}
                      >
                        Web Development
                      </Link>
                    </li>
                    <li className="red-color">
                      <Link
                        to="/professional-web-development/"
                        onClick={() => closeDropdownMenu()}
                      >
                        Professional Web Development
                      </Link>
                    </li>
                    <li className="red-color">
                      <Link
                        to="/website-maintenance/"
                        onClick={() => closeDropdownMenu()}
                      >
                        Website Maintenance
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <Link to="/portfolio/" onClick={() => closeMenuTrigger()}>
                  Portfolio
                </Link>
              </li>
              <li>
                <a href="/clutch-review/">Reviews</a>
              </li>
              <li>
                <Link to="/about-us/" onClick={() => closeMenuTrigger()}>
                  About
                </Link>
              </li>
              <li>
                <Link to="/contact-us/" onClick={() => closeMenuTrigger()}>
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
          <div className="header-right">
            <div
              className="humberger-menu d-block d-laptop-device-none "
              ref={ref}
              onClick={handleClickOutside}
            >
              <span onClick={menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-laptop-device-none">
              <span onClick={closeMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
        <div className="header-right mobileviewonly">
          <nav className="mainmenunav ">
            <ul className="mainmenu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="has-droupdown">
                <Link to="/services/">Services</Link>
                <ul className="submenu">
                  <li className="has-droupdown">
                    <Link to="/bespoke-web-development/">
                      Bespoke Web Development
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link to="/web-app-development/">
                          Web App Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/full-stack-web-development/">
                          Full Stack Web Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/uk-website-company/">
                          Uk Website Company
                        </Link>
                      </li>
                      <li>
                        <Link to="/python-developers-uk/">
                          Python Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/laravel-developers-uk/">
                          Laravel Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="has-droupdown">
                    <Link to="/cms-development/">CMS Development</Link>
                    <ul className="submenu">
                      <li>
                        <Link to="/shopify-developers-uk/">
                          Shopify Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/woocommerce-development/">
                          Woocommerce Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/wordpress-development/">
                          Wordpress Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/magento-development/">
                          Magento Development
                        </Link>
                      </li>
                      <li>
                        <Link to="/drupal-development/">
                          Drupal Development
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/web-development/">Web Development</Link>
                  </li>
                  <li>
                    <Link to="/professional-web-development/">
                      Professional Web Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/website-maintenance/">Website Maintenance</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/portfolio/">Portfolio</Link>
              </li>
              <li>
                <a href="/clutch-review/">Reviews</a>
              </li>
              <li>
                <Link to="/about-us/">About</Link>
              </li>
              <li>
                <Link to="/contact-us/">Contact</Link>
              </li>
            </ul>
          </nav>
          <div className="header-right">
            <div className="humberger-menu d-block d-lg-none pl--20 tab">
              <span onClick={menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={closeMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
