import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import LazyLoad from "react-lazyload";

const AboutUsFooter = ({ sect }) => {
  return (
    <>
      <LazyLoad height={200} once>
        <footer className="footer-area ">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>{sect.subTitle}</span>
                    <h2>
                      {sect.title}
                      <br />
                      {sect.titleSecondLine}
                    </h2>
                    <HashLink
                      className="rn-button-style--2"
                      to="/contact-us/#contact-page-form"
                    >
                      <span>{sect.buttontxt}</span>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Quick Link</h4>
                        <ul className="ft-link">
                          <li>
                            <Link to="/portfolio/">Work</Link>
                          </li>
                          <li>
                            <Link to="/about-us/">About</Link>
                          </li>
                          <li>
                            <Link to="/contact-us/">Let's Talk</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Say Hello</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:joe@omtechmasters.co.uk">
                              joe@omtechmasters.co.uk
                            </a>
                          </li>
                          <li>
                            <a href="tel:0116 393 0838">0116 393 0838</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>
                          Omtech Masters Ltd is registered in England and Wales.
                          <br></br> Company number 10611177 | All rights
                          reserved. © 2024
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </LazyLoad>
    </>
  );
};

export default AboutUsFooter;
