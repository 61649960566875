import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { HashLink } from "react-router-hash-link";
import LazyLoad from "react-lazyload";

const CounterButtonHome = ({ sect, card }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const onVisibilityChange = (isVisible) => {
    if (isVisible && !didViewCountUp) {
      setDidViewCountUp(true);
    }
  };

  return (
    <>
      <LazyLoad height={200} once>
        <div
          className="rn-counterup-area pt--120 pb--50 "
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_BACKEND_URL +
              sect?.bgImg?.data?.attributes?.url
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">{sect.title}</h3>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
                <div>
                  <h5 className="counter">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? sect.firstCounter : 0} />
                    </VisibilitySensor>
                  </h5>

                  <p className="description">{sect.firstCounterDec}</p>
                </div>
              </div>
              <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
                <div>
                  <h5 className="counter">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? sect.secondCounter : 0} />
                    </VisibilitySensor>
                  </h5>

                  <p className="description">{sect.secondCounterDec}</p>
                </div>
              </div>
              <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12">
                <div>
                  <h5 className="counter">
                    <VisibilitySensor
                      onChange={onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp end={didViewCountUp ? sect.thirdCounter : 0} />
                    </VisibilitySensor>
                  </h5>

                  <p className="description">{sect.thridCounterDec}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--40 text-center">
                  <HashLink
                    className="rn-button-style--20 btn-solid"
                    to="/contact-us/#contact-page-form"
                  >
                    <span>{sect.ctaButtontext}</span>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default CounterButtonHome;
