import React from "react";
import LazyLoad from "react-lazyload";

const Team = ({ sect }) => {
  return (
    <>
      <div className="rn-team-area bg_color--1 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--25">
                <h2 className="title">{sect.title}</h2>
                <p>{sect.description}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            {sect.teamCard.map((val, i) => (
              <>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="team">
                    <div className="thumbnail">
                      <LazyLoad height={200} offset={100}>
                        <img
                          className="w-100"
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}` +
                            val.img.data.attributes.url
                          }
                          alt="Blog Images"
                        />
                      </LazyLoad>
                    </div>
                    <div className="content">
                      <h4 className="title">{val.name}</h4>
                      <p className="designation">{val.designation}</p>
                      <p className="designation">{val.description}</p>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
