import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Topbar from "../global/Topbar";
/* import GlobalPopupCard from "../global/GlobalPopupCard";
 */
const PortfolioDetailsHeader = ({ sect }) => {
  const location = useLocation();

  const slugFromRoute = location.pathname.replace("/portfolio-details/", "");

  const [data, setData] = useState([]);
  const [project, setProject] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
        const matchingProject = data.data.find(
          (project) => project.attributes.slug === slugFromRoute
        );
        setProject(matchingProject);
      });
  }, [slugFromRoute]);

  const isUnicyclePath = location.pathname.includes("unicycle");

  return (
    <React.Fragment>
      <Topbar />
{/*       <GlobalPopupCard />
 */}      <div
        className={`breadcrumb-area rn-bg-color ptb--120 bg_image ${
          isUnicyclePath ? "unicycle-bg" : ""
        }`}
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_BACKEND_URL +
            (project?.attributes.headerImg.data.attributes.url || "")
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner pt--100">
                <h2 className="title">{project?.attributes.name}</h2>
                <ul className="page-list">
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}`}>
                      {sect.subTitle}
                    </Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}/portfolio/`}>
                      Portfolio
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {project?.attributes.name || ""}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PortfolioDetailsHeader;
