import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Services = ({ sect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/services/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  return (
    <>
      <div className="service-area pt--60 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">{sect.title}</h2>
                <p>{sect.description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            {data.map((val, i) => {
              const customColors = ["1", "2", "3", "4", "5", "6"];
              const customColorClass = customColors[i % customColors.length];
              return (
                <div
                  className={`col-lg-4 col-md-6 col-sm-6 col-12 pt--40 text-center  d-flex align-items-stretch`}
                  key={i}
                >
                  <Link to={val.attributes?.link}>
                    <div
                      className={`singleTwo-column custom-color custom-color--${customColorClass} service service__style--2 `}
                    >
                      <div className="icon">
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}` +
                            val?.attributes?.img.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="content-wd">
                        <h3 className="title">{val.attributes.title}</h3>
                        <p>{val.attributes.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="view-more-btn mt--100 text-center">
                <HashLink
                  className="rn-button-style--20 btn-solid"
                  to="/contact-us/#contact-page-form"
                >
                  <span>Let’s Connect!</span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
