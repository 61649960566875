import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import Testimonials from "../global/Testimonials";
import NarrowClutch from "../global/NarrowClutch";
import { HashLink } from "react-router-hash-link";

const ServicesInner = ({ sect }) => {
  const [dataz, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/services/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  const [data, setDatao] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setDatao(data.data);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <div className="service-area pt--120 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">{sect.SubmeanTitle}</h2>
                <p>{sect.SubmeanuDes}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <NarrowClutch />
      <div className="row">
        <div className="col-lg-12">
          <div className="view-more-btn mt--60 text-center">
            <HashLink
              className="rn-button-style--2 btn-solid"
              to="/contact-us/#contact-page-form"
            >
              <span>GET IN TOUCH</span>
            </HashLink>
          </div>
        </div>
      </div>
      <div className="service-area pt--120 pb--120 bg_color--1">
        <div className="container">
          <div className="row">
            {dataz.map((val, i) => {
              const customColors = ["1", "2", "3", "4", "5", "6"];
              const customColorClass = customColors[i % customColors.length];

              return (
                <div
                  className={`col-lg-4 col-md-6 col-sm-6 col-12 pt--40 text-center  d-flex align-items-stretch`}
                  key={i}
                >
                  <Link to={val.attributes.link}>
                    <div
                      className={`singleTwo-column custom-color custom-color--${customColorClass} service service__style--2 `}
                    >
                      <div className="icon">
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}` +
                            val?.attributes?.img.data?.attributes?.url
                          }
                          alt=""
                        />
                      </div>
                      <div className="content-wd">
                        <h3 className="title">{val.attributes.title}</h3>
                        <p>{val.attributes.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="view-more-btn mt--60 text-center">
                <HashLink
                  className="rn-button-style--2 btn-solid"
                  to="/contact-us/#contact-page-form"
                >
                  <span>Start your journey today</span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-area ptb--20 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">Our Latest Projects</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper portfolio-sacousel-inner mb--55">
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...settings} {...portfolioSlick2}>
              {data.map((value, index) => (
                <Link
                  to={{
                    pathname: `/portfolio-details/${value.attributes.slug}`,
                    state: {
                      projectTitle: value.attributes.projectTitle,
                      projectDes: value.attributes.projectDes,
                      platform: value.attributes.platform,
                      projectDuration: value.attributes.projectDuration,
                      websiteUrl: value.attributes.websiteUrl,
                      imgUrl: value.attributes.portfolioImg.data.attributes.url,
                      headerImg: value.attributes.headerImg.data.attributes.url,
                      name: value.attributes.name,
                    },
                  }}
                >
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_BACKEND_URL +
                            value.attributes.Img.data[0].attributes.url
                          })`,
                        }}
                      ></div>

                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <p>{value.attributes.category}</p>
                        <h5>
                          <Link
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            {value.attributes.title}
                          </Link>
                        </h5>

                        <div className="portfolio-button">
                          <Link
                            className="rn-btn"
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Link
                      className="link-overlay"
                      to={{
                        pathname: `/portfolio-details/${value.attributes.slug}`,
                        state: {
                          projectTitle: value.attributes.projectTitle,
                          projectDes: value.attributes.projectDes,
                          platform: value.attributes.platform,
                          projectDuration: value.attributes.projectDuration,
                          websiteUrl: value.attributes.websiteUrl,
                          imgUrl:
                            value.attributes.portfolioImg.data.attributes.url,
                          headerImg:
                            value.attributes.headerImg.data.attributes.url,
                          name: value.attributes.name,
                        },
                      }}
                    ></Link>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Testimonials />
    </>
  );
};

export default ServicesInner;
