import React, { useRef, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { HashLink } from "react-router-hash-link";
import LazyLoad from "react-lazyload";

const AboutTwo = ({ sect }) => {
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;
    const widgetElement = document.querySelector(".cluthtwo");

    if (
      element instanceof Node &&
      widgetElement instanceof Node &&
      widgetElement !== element &&
      !element.contains(widgetElement)
    ) {
      element.appendChild(widgetElement);
    } else {
      console.error(
        "Widget element not found or attempting to append to itself"
      );
    }
  }, [ref]);

  return (
    <>
      <div className="rn-about-area pb--80 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 order-2 pt--40 order-lg-1">
                <div className="thumbnail">
                  <LazyLoad height={200} offset={100}>
                    <img
                      className="w-100"
                      src={
                        `${process.env.REACT_APP_BACKEND_URL}` +
                        sect.img.data.attributes.url
                      }
                      alt="About Images"
                    />
                  </LazyLoad>
                </div>
              </div>
              <div className="col-lg-7 order-1 order-lg-2">  
                <div className="about-inner inner">
                  <div className="section-title">
                    <h1 className="title">{sect.title}</h1>
                    <p className="description">{sect.description}</p>
                    <p className="description">{sect.descriptionTwo}</p>
                    <p className="description">{sect.descriptionThree}</p>
                  </div>
                  <div className="row mt--30">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">{sect.subTitleOne}</h3>
                        {sect.aboutPointTwo.map((name, index) => {
                          return (
                            <p key={index}>
                              {" "}
                              <FiCheck /> {name.points}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title">{sect.subTitleTwo}</h3>
                        {sect.aboutPoints.map((name, index) => {
                          return (
                            <p key={index}>
                              {" "}
                              <FiCheck /> {name.points}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <HashLink
                    className="rn-button-style--20 btn-solid"
                    to="/contact-us/#contact-page-form"
                  >
                    <span>{sect.ctaButtontext}</span>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTwo;
