import React, { useRef, useEffect, useState, useLocation } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { slickDot, portfolioSlick2 } from "../page-demo/script";
import Testimonials from "../global/Testimonials";
import NarrowClutch from "../global/NarrowClutch";
import CMS from "./ServicesCollection/CMS";
import { HashLink } from "react-router-hash-link";

const CMSServicesInner2 = ({ sect }) => {
  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    // Check if the element and widgetElement exist
    if (element instanceof Node) {
      const widgetElement = document.querySelector(".cluthtwo");

      // Check if the widgetElement exists and is not the same as the element
      if (
        widgetElement instanceof Node &&
        widgetElement !== element &&
        !element.contains(widgetElement)
      ) {
        element.appendChild(widgetElement);
      } else {
        console.error(
          "Widget element not found or attempting to append to itself"
        );
      }
    } else {
      console.error("Element not found");
    }
  }, [ref]);

  const [data, setDatao] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setDatao(data.data);
      });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      {/* <div className="service-area pt--120  bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">{sect.Headline}</h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="service-area pt--120 pb--50 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">{sect.title}</h2>
                <p>{sect.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div ref={ref} className="clutch-review-sectiontwo"></div> */}
      <NarrowClutch />
      <div className="row">
        <div className="col-lg-12 pt--90">
          <div className="view-more-btn text-center">
            <HashLink
              className="rn-button-style--2 btn-solid"
              to="/contact-us/#contact-page-form"
            >
              <span>GET IN TOUCH</span>
            </HashLink>
          </div>
        </div>
      </div>
      <CMS />

      <div className="portfolio-area ptb--20 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">Our Latest Projects</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper portfolio-sacousel-inner mb--55">
          <div className="portfolio-slick-activation mt--70 mt_sm--40">
            <Slider {...settings} {...portfolioSlick2}>
              {data.map((value, index) => (
                <Link
                  to={{
                    pathname: `/portfolio-details/${value.attributes.slug}`,
                    state: {
                      projectTitle: value.attributes.projectTitle,
                      projectDes: value.attributes.projectDes,
                      platform: value.attributes.platform,
                      projectDuration: value.attributes.projectDuration,
                      websiteUrl: value.attributes.websiteUrl,
                      imgUrl: value.attributes.portfolioImg.data.attributes.url,
                      headerImg: value.attributes.headerImg.data.attributes.url,
                      name: value.attributes.name,
                    },
                  }}
                >
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_BACKEND_URL +
                            value.attributes.Img.data[0].attributes.url
                          })`,
                        }}
                      ></div>

                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <p>{value.attributes.category}</p>
                        <h5>
                          <Link
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            {value.attributes.title}
                          </Link>
                        </h5>

                        <div className="portfolio-button">
                          <Link
                            className="rn-btn"
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Link
                      className="link-overlay"
                      to={{
                        pathname: `/portfolio-details/${value.attributes.slug}`,
                        state: {
                          projectTitle: value.attributes.projectTitle,
                          projectDes: value.attributes.projectDes,
                          platform: value.attributes.platform,
                          projectDuration: value.attributes.projectDuration,
                          websiteUrl: value.attributes.websiteUrl,
                          imgUrl:
                            value.attributes.portfolioImg.data.attributes.url,
                          headerImg:
                            value.attributes.headerImg.data.attributes.url,
                          name: value.attributes.name,
                        },
                      }}
                    ></Link>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Testimonials />
    </>
  );
};

export default CMSServicesInner2;
