import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "./component/header/Header";
import Pages from "./component/pages/Pages";

import PageScrollTop from "./component/PageScrollTop";
/* import { SkeletonTheme } from "react-loading-skeleton";
 */
const Root = () => {
  return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Header logo="light" color="color-white" />
          <Switch>
            <Route exact path="/" render={() => <Pages paramsSlug="home" />} />
            <Route
              path="/:slug"
              render={(props) => <Pages paramsSlug={props.match.params.slug} />}
            />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
