import React from "react";
import Topbar from "../global/Topbar";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
//import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Sliders = ({ sect }) => {
  return (
    <>
      <Topbar />

      <div className="slider-wrapper color-white">
        <div className="slider-activation slider-digital-agency">
          {sect.sliderOne.map((value, index) => {
            // Store the desktop background image URL in a variable
            const desktopBackgroundImageUrl =
              process.env.REACT_APP_BACKEND_URL +
              value.bgImage.data.attributes.url;
            // Store the mobile background image URL in a variable
            const mobileBackgroundImageUrl =
              process.env.REACT_APP_BACKEND_URL +
              value.mobile_Img.data.attributes.url;

            return (
              <div key={index}>
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image`}
                data-black-overlay="2"
              >
                {/* Use media queries to define different background images */}
                <style>
                  {`
                    @media (max-width: 608px) {
                      .slide.slide-style-2 {
                        background-image: url(${mobileBackgroundImageUrl});
                      }
                    }
                    @media (min-width: 609px) {
                      .slide.slide-style-2 {
                        background-image: url(${desktopBackgroundImageUrl});
                      }
                    }
                  `}
                </style>
                  {/* Use a transparent 1x1 pixel image as a placeholder */}
                  
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category && <span>{value.category}</span>}
                          {value.title && (
                            <h1 className="title">{value.title}</h1>
                          )}
                          {value.description && (
                            <p className="description">{value.description}</p>
                          )}
                          {value.buttonText && (
                            <div className="slide-btn">
                              <HashLink
                                className="rn-button-style--2 btn-solid"
                                to={`${value.buttonLink}#contact-page-form`}
                                style={{ color: "none" }}
                              >
                                {value.buttontxt2}
                              </HashLink>
                              <Link
                                className="rn-button-style--2 btn-solid"
                                to={`${value.buttonLink2}`}
                              >
                                {value.buttonText}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sliders;
