import React, { useEffect } from "react";
import emailjs from "emailjs-com";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { useLocation, useHistory } from "react-router-dom";



const ContactFrom2 = ({ sect }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const hash = location.hash;
    if (hash === "#contact-page-form") {
      const contactFormElement = document.getElementById("contact-page-form");
      if (contactFormElement) {
        contactFormElement.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }
  }, [location.hash]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fjk1njd",
        "template_54yuk4m",
        e.target,
        "BX0WtIBtVDQ5s29L9"
      )
      .then(
        () => {
          history.push("/thankyou");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      <div className=" rn-contact-page ptb--120 bg_color--1">
        <div className="contact-form--1">
          <div className="container ">
            <div className="row row--35 align-items-start">
              <div
                id="contact-page-form"
                className="col-lg-6 order-2 order-lg-1 "
              >
                <div className="section-title text-left mb--50">
                  <h2 className="title">{sect.title}</h2>
                  <p className="description">{sect.description}</p>
                </div>
                <div className="form-wrapper">
                  <form action="" onSubmit={sendEmail}>
                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="fullname"
                        placeholder="Your Name"
                        required
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="email"
                        name="email"
                        placeholder="Your E-mail"
                        required
                      />
                    </div>

                    <div className="rn-form-group">
                      <input
                        type="text"
                        name="phone"
                        placeholder="Your Phone"
                      />
                    </div>

                    <div className="rn-form-group">
                      <textarea
                        name="message"
                        placeholder="How can we help?"
                      ></textarea>
                    </div>

                    <div className="rn-form-group">
                      <button
                        className="rn-button-style--2 btn-solid"
                        type="submit"
                        value="submit"
                        name="submit"
                        id="mc-embedded-subscribe"
                      >
                        GET A QUOTE NOW !
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img src="/assets/images/about/about-6.jpg" alt="trydo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 order-1">
              <div className="rn-address">
                <div className="icons">
                  <FiHeadphones />
                </div>
                <div className="inner" style={{ paddingBottom: "32px" }}>
                  <h4 className="title">Email us</h4>
                  <p>
                    <a href="mailto:JOE@OMTECHMASTERS.CO.UK">
                      JOE@OMTECHMASTERS.CO.UK
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12   order-2">
              <div className="rn-address">
                <div className="icons">
                  <FiMapPin />
                </div>
                <div className="inner" style={{ paddingBottom: "32px" }}>
                  <h4 className="title">Call us</h4>
                  <p>
                    <a href="tel:0116 393 0838">0116 393 0838</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12  order-3">
              <div className="rn-address">
                <div className="icons">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Visit us</h4>
                  <p>
                    BUSINESS LOUNGE, 4 MIDLAND <br />
                    STREET, LEICESTER LE1 1TG
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactFrom2;
