import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FiCheck } from "react-icons/fi";
import CounterOne from "../global/CouinterOne";

const About = ({ sect }) => {
  return (
    <>
      <CounterOne />
      <div className="about-area ptb--120  bg_image bg_image--3">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={
                      `${process.env.REACT_APP_BACKEND_URL}` +
                      sect.img.data.attributes.url
                    }
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{sect.title}</h2>
                    <p className="description">{sect.description}</p>
                  </div>
                  <div className="row mt--30">
                    <div>
                      {/* Start Tabs Area */}
                      <div className="tabs-area">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12">
                              <Tabs>
                                <TabList className="tab-style--1">
                                  <Tab>{sect.tab1}</Tab>
                                  <Tab>{sect.tab2}</Tab>
                                  <Tab>{sect.tab3}</Tab>
                                </TabList>

                                <TabPanel>
                                  <div className="single-tab-content">
                                    <p>{sect.tab1description}</p>

                                    <div className="mt--30">
                                      <h4>{sect.tab1innertitle}</h4>
                                      <ul className="list-style--1">
                                        {sect.bulletpoint.map((name, index) => {
                                          return (
                                            <li key={index}>
                                              <FiCheck /> {name.points}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </TabPanel>

                                <TabPanel>
                                  <div className="single-tab-content">
                                    <p>{sect.tab2description}</p>
                                    <p>{sect.tab2descriptionp2}</p>
                                    <p>{sect.tab2descriptionp3}</p>
                                  </div>
                                </TabPanel>

                                <TabPanel>
                                  <div className="single-tab-content">
                                    <p>{sect.tab3description}</p>
                                    <p>{sect.tab3descriptionp2}</p>
                                    <p>{sect.tab3descriptionp3}</p>
                                  </div>
                                </TabPanel>
                              </Tabs>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Tabs Area */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
