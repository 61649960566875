import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import Loaders from "../Loader/Loaders";
import ReactLoadable from 'react-loadable';




import Sliders from "../Home/Sliders";
import Portfolio from "../Home/Portfolio";
import About from "../Home/About";
import OurClients from "../Home/OurClients";
import Header from "../AboutUs/Header";
import AboutTwo from "../AboutUs/AboutTwo";
import Counterup from "../AboutUs/Counterup";
import AboutUsFooter from "../AboutUs/AboutUsFooter";
import ScrollTop from "../global/ScrollTop";
import PortfolioDetailsHeader from "../PortfolioDetails/PortfolioDetailsHeader";
import PortfolioDetails from "../PortfolioDetails/PortfolioDetails";
import RealtedWork from "../PortfolioDetails/RealtedWork";
import ContactForm from "../Contact/ContactForm";
import ContactMap from "../Contact/ContactMap";
import Clutch from "../Reviews/Clutch";
import AllPortfolio from "../Portfolio/AllPortfolio";
import TechPartners from "../Home/TechPartners";
import ServicesTwo from "../Home/ServicesTwo";
import ServicesThree from "../Home/ServicesThree";
import Services from "../Services/Services";
import Testimonials from "../global/Testimonials";
import AboutServices from "../AboutUs/AboutServices";
import Team from "../AboutUs/Team";
import CounterHome from "../Home/CounterHome";
import SrvcSec4 from "../Services/SrvcSec4";
import ContactFrom2 from "../Contact/ContactFrom2";
import ServicesInner from "../Services/ServicesInner";
import ServicesContactForm from "../Services/ServicesContactForm";
import ServicesSec from "../Services/ServicesSec";
import NarrowClutch from "../global/NarrowClutch";
import SrvcHeader from "../Services/SrvcHeader";
import CMSServicesInner2 from "../Services/CMSServicesInner2";
import HomeServices from "../Home/HomeServices";
import CounterButtonHome from "../Home/CounterButtonHome";
import HomeButtonAbout from "../Home/HomeButtonAbout";
import ServicesButton1 from "../Services/ServicesButton1";
import ServicesButton2 from "../Services/ServicesButton2";
import ServicesButton3 from "../Services/ServicesButton3";
import ServicesInnerTemp2Button from "../Services/ServiceInnerTemp2/ServicesInnerTemp2Button";
import ServicesTestimonialsButtom from "../Services/ServiceInnerTemp2/ServicesTestimonialsButtom";
import ServicesInnerClientButton from "../Services/ServiceInnerTemp2/ServicesInnerClientButton";
import AboutClientButton from "../AboutUs/AboutClientButton";
import AboutusContactForm from "../AboutUs/AboutusContactForm";
import BespkeWDSServicesInner2 from "../Services/BespkeWDSServicesInner2";
import ProffesionalWDSServiceInner2 from "../Services/ProffesionalWDSServiceInner2";
import Pricing from "../Services/Pricing";
import Thankyou from "../Thankyou/Thankyou";
import ServicesRichT from "../Services/ServicesRichT";
import ServicesSecRichT from "../Services/ServicesSecRichT";
import ServicesButtonServicePage from "../Services/ServicesButtonServicePage";

/* const SlidersLoadable = ReactLoadable({
  loader: () => import("../Home/Sliders"),
  loading: () => <Loaders />,
});

const HomeServices = ReactLoadable({
  loader: () => import("../Home/HomeServices"),
  loading: () => <Loaders />,
});

 */


const Pages = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  const [paramsSlug, setParamsSlug] = useState("");
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof params.slug === "undefined") {
      return setParamsSlug("home");
    } else {
      return setParamsSlug(params.slug);
    }
  }, [params.slug]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/pages/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
        setIsLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Loaders />
    );
  }

  return (
    <div>
      {data.map((attribute) => {
        if (attribute.attributes && attribute.attributes.slug === paramsSlug) {
          if (
            attribute.attributes.Homepage &&
            attribute.attributes.Homepage.length > 0
          ) {
            return attribute.attributes.Homepage.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return <Sliders key={`slider-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <HomeServices key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.narrowclutch") {
                return <NarrowClutch key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterButtonHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <HomeButtonAbout key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.service-two") {
                return <ServicesTwo key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.service-three") {
                return <ServicesThree key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return <Testimonials key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return <OurClients key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.aboutus &&
            attribute.attributes.aboutus.length > 0
          ) {
            return attribute.attributes.aboutus.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.about") {
                return <AboutTwo key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.narrowclutch") {
                return <NarrowClutch key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return (
                  <AboutClientButton key={`services-${key}`} sect={item} />
                );
              } else if (item.__component === "aboutus.all-counter-up") {
                return <Counterup key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return <Testimonials key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.team") {
                return <Team key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return <AboutServices key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return (
                  <AboutusContactForm key={`services-${key}`} sect={item} />
                );
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.portfolio &&
            attribute.attributes.portfolio.length > 0
          ) {
            return attribute.attributes.portfolio.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`slider-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <AllPortfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.portfolioDetails &&
            attribute.attributes.portfolioDetails.length > 0
          ) {
            return attribute.attributes.portfolioDetails.map((item, key) => {
              if (item.__component === "portfolio-detail.header") {
                return (
                  <PortfolioDetailsHeader key={`aboutus-${key}`} sect={item} />
                );
              } else if (
                item.__component === "portfolio-detail.portfolio-detail"
              ) {
                return <PortfolioDetails key={`services-${key}`} sect={item} />;
              } else if (item.__component === "portfolio-detail.related-work") {
                return <RealtedWork key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.contact &&
            attribute.attributes.contact.length > 0
          ) {
            return attribute.attributes.contact.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "contact.contact-form2") {
                return <ContactFrom2 key={`services-${key}`} sect={item} />;
              } else if (item.__component === "contact.contact-map") {
                return <ContactMap key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.reviews &&
            attribute.attributes.reviews.length > 0
          ) {
            return attribute.attributes.reviews.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`aboutus-${key}`} sect={item} />;
              }
              if (item.__component === "review.clutch") {
                return <Clutch key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return <Testimonials key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }
          if (
            attribute.attributes.services &&
            attribute.attributes.services.length > 0
          ) {
            return attribute.attributes.services.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`slider-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <ServicesButtonServicePage key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec2") {
                return <ServicesButton2 key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec3") {
                return <ServicesButton3 key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec4") {
                return <SrvcSec4 key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.webdevelopment &&
            attribute.attributes.webdevelopment.length > 0
          ) {
            return attribute.attributes.webdevelopment.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <SrvcHeader key={`slider-${key}`} sect={item} />;
              } else if (item.__component === "service-page.title") {
                return <ServicesInner key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }

              return null;
            });
          }

          if (
            attribute.attributes.cmsdevelopment &&
            attribute.attributes.cmsdevelopment.length > 0
          ) {
            return attribute.attributes.cmsdevelopment.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <SrvcHeader key={`slider-${key}`} sect={item} />;
              } else if (item.__component === "service-page.services-inner") {
                return (
                  <CMSServicesInner2 key={`services-${key}`} sect={item} />
                );
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.bespokewebdevelopment &&
            attribute.attributes.bespokewebdevelopment.length > 0
          ) {
            return attribute.attributes.bespokewebdevelopment.map(
              (item, key) => {
                if (item.__component === "aboutus.header") {
                  return <SrvcHeader key={`slider-${key}`} sect={item} />;
                } else if (item.__component === "service-page.services-inner") {
                  return (
                    <BespkeWDSServicesInner2
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "global.contact") {
                  return <ContactForm key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.footer") {
                  return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "global.scroll-top") {
                  return <ScrollTop key={`services-${key}`} sect={item} />;
                }
                return null;
              }
            );
          }

          if (
            attribute.attributes.professionalwebdevelopment &&
            attribute.attributes.professionalwebdevelopment.length > 0
          ) {
            return attribute.attributes.professionalwebdevelopment.map(
              (item, key) => {
                if (item.__component === "aboutus.header") {
                  return <SrvcHeader key={`slider-${key}`} sect={item} />;
                } else if (item.__component === "service-page.services-inner") {
                  return (
                    <ProffesionalWDSServiceInner2
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "global.contact") {
                  return <ContactForm key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.footer") {
                  return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "global.scroll-top") {
                  return <ScrollTop key={`services-${key}`} sect={item} />;
                }
                return null;
              }
            );
          }

          if (
            attribute.attributes.webappdevelopment &&
            attribute.attributes.webappdevelopment.length > 0
          ) {
            return attribute.attributes.webappdevelopment.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return (
                  <ServicesContactForm key={`slider-${key}`} sect={item} />
                );
              } else if (item.__component === "service-page.header-form") {
                return <ServicesSec key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return (
                  <ServicesInnerTemp2Button
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return (
                  <ServicesTestimonialsButtom
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.service-three") {
                return <ServicesThree key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return (
                  <ServicesInnerClientButton
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "service-page.srvc-sec1") {
                return <ServicesRichT key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <About key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.woocommercedevelopment &&
            attribute.attributes.woocommercedevelopment.length > 0
          ) {
            return attribute.attributes.woocommercedevelopment.map(
              (item, key) => {
                if (item.__component === "home.all-slider") {
                  return (
                    <ServicesContactForm key={`slider-${key}`} sect={item} />
                  );
                } else if (item.__component === "service-page.header-form") {
                  return <ServicesSec key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.services") {
                  return (
                    <ServicesInnerTemp2Button
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.tech-partner") {
                  return <TechPartners key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.portfolio") {
                  return <Portfolio key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.testimonial") {
                  return (
                    <ServicesTestimonialsButtom
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.service-three") {
                  return <ServicesThree key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.client") {
                  return <OurClients key={`services-${key}`} sect={item} />;
                } else if (item.__component === "service-page.srvc-sec1") {
                  return <Services key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.all-counter-up") {
                  return <CounterHome key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "home.about") {
                  return <About key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.contact") {
                  return <ContactForm key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.footer") {
                  return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "global.scroll-top") {
                  return <ScrollTop key={`services-${key}`} sect={item} />;
                }
                return null;
              }
            );
          }

          if (
            attribute.attributes.fullstackwebdevelopment &&
            attribute.attributes.fullstackwebdevelopment.length > 0
          ) {
            return attribute.attributes.fullstackwebdevelopment.map(
              (item, key) => {
                if (item.__component === "home.all-slider") {
                  return (
                    <ServicesContactForm key={`slider-${key}`} sect={item} />
                  );
                } else if (item.__component === "service-page.header-form") {
                  return <ServicesSec key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.services") {
                  return (
                    <ServicesInnerTemp2Button
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.tech-partner") {
                  return <TechPartners key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.portfolio") {
                  return <Portfolio key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.testimonial") {
                  return (
                    <ServicesTestimonialsButtom
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.service-three") {
                  return <ServicesThree key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.client") {
                  return <OurClients key={`services-${key}`} sect={item} />;
                } else if (item.__component === "service-page.srvc-sec1") {
                  return <ServicesRichT key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.all-counter-up") {
                  return <CounterHome key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "home.about") {
                  return <About key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.contact") {
                  return <ContactForm key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.footer") {
                  return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "global.scroll-top") {
                  return <ScrollTop key={`services-${key}`} sect={item} />;
                }
                return null;
              }
            );
          }

          if (
            attribute.attributes.wordpressdevelopment &&
            attribute.attributes.wordpressdevelopment.length > 0
          ) {
            return attribute.attributes.wordpressdevelopment.map(
              (item, key) => {
                if (item.__component === "home.all-slider") {
                  return (
                    <ServicesContactForm key={`slider-${key}`} sect={item} />
                  );
                } else if (item.__component === "service-page.header-form") {
                  return <ServicesSec key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.services") {
                  return (
                    <ServicesInnerTemp2Button
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.tech-partner") {
                  return <TechPartners key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.portfolio") {
                  return <Portfolio key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.testimonial") {
                  return (
                    <ServicesTestimonialsButtom
                      key={`services-${key}`}
                      sect={item}
                    />
                  );
                } else if (item.__component === "home.service-three") {
                  return <ServicesThree key={`services-${key}`} sect={item} />;
                } else if (item.__component === "home.client") {
                  return <OurClients key={`services-${key}`} sect={item} />;
                } else if (item.__component === "service-page.srvc-sec1") {
                  return <Services key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.all-counter-up") {
                  return <CounterHome key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "home.about") {
                  return <About key={`services-${key}`} sect={item} />;
                } else if (item.__component === "global.contact") {
                  return <ContactForm key={`services-${key}`} sect={item} />;
                } else if (item.__component === "aboutus.footer") {
                  return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
                } else if (item.__component === "global.scroll-top") {
                  return <ScrollTop key={`services-${key}`} sect={item} />;
                }
                return null;
              }
            );
          }

          if (
            attribute.attributes.magentodevelopment &&
            attribute.attributes.magentodevelopment.length > 0
          ) {
            return attribute.attributes.magentodevelopment.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return (
                  <ServicesContactForm key={`slider-${key}`} sect={item} />
                );
              } else if (item.__component === "service-page.header-form") {
                return <ServicesSec key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return (
                  <ServicesInnerTemp2Button
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return (
                  <ServicesTestimonialsButtom
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.service-three") {
                return <ServicesThree key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return <OurClients key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <Services key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <About key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.drupaldevelopment &&
            attribute.attributes.drupaldevelopment.length > 0
          ) {
            return attribute.attributes.drupaldevelopment.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return (
                  <ServicesContactForm key={`slider-${key}`} sect={item} />
                );
              } else if (item.__component === "service-page.header-form") {
                return <ServicesSec key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return (
                  <ServicesInnerTemp2Button
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return (
                  <ServicesTestimonialsButtom
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.service-three") {
                return <ServicesThree key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return <OurClients key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <Services key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <About key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.UKWebDevelopment &&
            attribute.attributes.UKWebDevelopment.length > 0
          ) {
            return attribute.attributes.UKWebDevelopment.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return (
                  <ServicesContactForm key={`slider-${key}`} sect={item} />
                );
              } else if (item.__component === "service-page.header-form") {
                return <ServicesSecRichT key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return (
                  <ServicesInnerTemp2Button
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return (
                  <ServicesTestimonialsButtom
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.service-three") {
                return <ServicesThree key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.client") {
                return <OurClients key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <Services key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <About key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.websiteMaintenance &&
            attribute.attributes.websiteMaintenance.length > 0
          ) {
            return attribute.attributes.websiteMaintenance.map((item, key) => {
              if (item.__component === "home.all-slider") {
                return (
                  <ServicesContactForm key={`slider-${key}`} sect={item} />
                );
              } else if (item.__component === "service-page.pricing") {
                return <Pricing key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.header-form") {
                return <ServicesSec key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.services") {
                return (
                  <ServicesInnerTemp2Button
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.tech-partner") {
                return <TechPartners key={`services-${key}`} sect={item} />;
              } else if (item.__component === "home.portfolio") {
                return <Portfolio key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.testimonial") {
                return (
                  <ServicesTestimonialsButtom
                    key={`services-${key}`}
                    sect={item}
                  />
                );
              } else if (item.__component === "home.client") {
                return <OurClients key={`services-${key}`} sect={item} />;
              } else if (item.__component === "service-page.srvc-sec1") {
                return <Services key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.all-counter-up") {
                return <CounterHome key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "home.about") {
                return <About key={`services-${key}`} sect={item} />;
              } else if (item.__component === "global.contact") {
                return <ContactForm key={`services-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.scroll-top") {
                return <ScrollTop key={`services-${key}`} sect={item} />;
              }
              return null;
            });
          }

          if (
            attribute.attributes.thankyou &&
            attribute.attributes.thankyou.length > 0
          ) {
            return attribute.attributes.thankyou.map((item, key) => {
              if (item.__component === "aboutus.header") {
                return <Header key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "global.thankyou") {
                return <Thankyou key={`aboutus-${key}`} sect={item} />;
              } else if (item.__component === "aboutus.footer") {
                return <AboutUsFooter key={`aboutus-${key}`} sect={item} />;
              }
              return null;
            });
          }
        }

        return null;
      })}
    </div>
  );
};

export default Pages;
