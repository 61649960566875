import React, { useState, useEffect } from "react";
import axios from "axios";
import LazyLoad from "react-lazyload";

const TechPartners = ({ sect }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/partners/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  return (
    <LazyLoad height={200} once>
      <div
        className="rn-brand-area brand-separation ptb--80 pb--80  "
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_BACKEND_URL +
            sect?.bgImg?.data?.attributes?.url
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--50">
                <h2 className="title" style={{ fontSize: "40px" }}>
                  {sect.title}
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="brand-style-2">
                {data.map((val, ind) =>
                  val.attributes.img.data.map((value, index) => (
                    <li key={index}>
                      <img
                        src={`${process.env.REACT_APP_BACKEND_URL}${value.attributes.url}`}
                        alt="linnworks"
                      />
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
};

export default TechPartners;
