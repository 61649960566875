import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import { useLocation } from "react-router-dom";

import "../Contact/widget.scss";

const Thankyou = () => {
  return (
    <>
      <div className="home-demo-area  pt--80 pb--80 pt_md--80 pb_md--90 pt_sm--60 pb_sm--60">
        <div className="wrapper plr--120">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center pb--30">
                <h2 className="theme-gradient maintitle">THANK YOU!</h2>
                <p>
                  Thank you for contacting us and we are really looking forward
                  to talking to you and helping you drive your internet strategy
                  forwards.
                </p>
                <p>
                  We will get back to you without delay but if your request is
                  urgent please call us on 0116 393 0838.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="contact-form-section" className="rn-contact-page pb--80 ">
        <div className="contact-form--1">
          <div className="row" style={{ marginRight: "0", marginLeft: "0" }}>
            <div className="col-md-12 text-center">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="row clutch-review-bottom">
                    <div className="col-md-12">
                      <a href="/clutch-review/">
                        <img
                          src="/assets/images/clutch/clutch-review-widget.png"
                          alt="clutch review"
                          width="250px"
                          height="64px"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row clutch-review-bottom">
                    <div className="col-md-12">
                      <a href="/clutch-review/">
                        <img
                          className="width"
                          src="/assets/images/clutch/optitop_clutch.co_web_developers_leicester_2024.webp"
                          alt="clutch top"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <div className="col-md-12">
                      <a
                        href="https://www.peopleperhour.com/freelancer/technology-programming/joe-itzcovitch-top-rated-php-web-development-zaqajqv#reviews"
                        rel="nofollow"
                        title="Check our reviews on PeoplePerHour"
                        className="button"
                        target="_blank"
                      >
                        <div className="pph-review">
                          <div className="container clearfix">
                            <div className="member-info boxmodelfix without-hourlies">
                              <div className="image">
                                <img
                                  src="https://dw3i9sxi97owk.cloudfront.net/uploads/thumbs/c29ea5064c2b5f6fd77c6274123a8dfe_150x150.jpg"
                                  alt=""
                                  width="61px"
                                  height="61px"
                                />
                              </div>
                              <div className="head">
                                <div className="name crop">Joe I.</div>
                                <div className="badges widget-member-prizes">
                                  <img
                                    className="prize"
                                    title="Top Endorsed"
                                    src="https://dw3i9sxi97owk.cloudfront.net/uploads/prizes/badges/ffdd1b856a16c89191f1150e4430076b.png"
                                    alt="Top Endorsed"
                                  />
                                </div>
                                <div className="identity clearfix">
                                  <ul>
                                    <li className="job-title crop">
                                      Top rated PHP Web Development | WordPress
                                      | Magento | Drupal | OpenCart | PrestaShop
                                      | Joomla
                                    </li>
                                    <li className="feedback crop">
                                      Rating: 100%
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              <div className="hire-button">
                                <a
                                  href="https://www.peopleperhour.com/freelancer/technology-programming/joe-itzcovitch-top-rated-php-web-development-zaqajqv#reviews"
                                  rel="nofollow"
                                  title="Check our reviews on PeoplePerHour"
                                  className="button"
                                  target="_blank"
                                >
                                  <span>Check our reviews on </span>
                                  <span className="logo">PeoplePerHour</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Thankyou;
