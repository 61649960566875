import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import LazyLoad from "react-lazyload";

const Testimonials = ({ sect }) => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const tabsRef = useRef(null);
  const intervalRef = useRef(null); // Reference to the interval

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/testimonials/?populate=deep`
      )
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(() => {
        setActiveTab((prevTab) => (prevTab + 1) % data.length);
      }, 2000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [data, isHovered]);

  const handleTabSelect = (index) => {
    clearInterval(intervalRef.current);
    setActiveTab(index);
  };

  return (
    <>
      <LazyLoad height={200} once>
        <div
          className={`rn-testimonial-area pt_sm--0 ptb--100 bg_color--${sect?.bgColor}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <h2 className="title" style={{ fontSize: "50px" }}>
                    What Our Clients Say
                  </h2>
                  <p>This is What We Live For</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <Tabs
                  selectedIndex={activeTab}
                  onSelect={handleTabSelect} // Modified to call handleTabSelect
                >
                  {data.map((value, index) => (
                    <TabPanel key={index}>
                      <div className="rn-testimonial-content text-center">
                        <div className="inner">
                          <p>{value.attributes.testimonial}</p>
                        </div>
                        <div className="author-info">
                          <h6>
                            <span>{value.attributes.testimonialName}</span>
                            {value.attributes.testimonialTag}
                          </h6>
                        </div>
                      </div>
                    </TabPanel>
                  ))}

                  <TabList className="testimonial-thumb-wrapper" ref={tabsRef}>
                    {data.map((value, index) => (
                      <Tab key={index}>
                        <div className="testimonial-thumbnai">
                          <div className="thumb">
                            <img
                              src={
                                `${process.env.REACT_APP_BACKEND_URL}` +
                                value.attributes.img.data.attributes.url
                              }
                              alt="Testimonial Images"
                            />
                          </div>
                        </div>
                      </Tab>
                    ))}
                  </TabList>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default Testimonials;
