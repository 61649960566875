import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import LazyLoad from "react-lazyload";

const ServicesThree = ({ sect }) => {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="service-area pt--120 pb--50 bg_color--5 ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <h2 className="title">{sect.title}</h2>
                    <p>{sect?.description}</p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            <div className="row d-flex  justify-content-center ">
              {sect.serviceCardT.map((val, i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 text-center  "
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">
                      <img
                        src={
                          `${process.env.REACT_APP_BACKEND_URL}` +
                          val?.img.data.attributes?.url
                        }
                        alt=""
                      />
                    </div>
                    <ScrollAnimation animateIn="flipInY">
                      <div className="content">
                        <h3 className="title">{val.title}</h3>
                        <p style={{ minHeight: "180px" }}>{val.description}</p>
                        <h4 className="subtitle"> {val.subTOne}</h4>
                        <p style={{ minHeight: "40px" }}>{val.subDOne}</p>
                        <h4 className="subtitle"> {val.subTTwo}</h4>
                        <p style={{ minHeight: "60px" }}>{val.subDTwo}</p>
                        <h4 className="subtitle"> {val.subTThree}</h4>
                        <p style={{ minHeight: "60px" }}>{val.subDThree}</p>
                        <h4 className="subtitle"> {val.subTFour}</h4>
                        <p style={{ minHeight: "60px" }}>{val.subDFour}</p>
                        <h4 className="subtitle"> {val.subTFive}</h4>
                        <p style={{ minHeight: "60px" }}>{val.subDFive}</p>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default ServicesThree;
