import React, { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import axios from "axios";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const ServicesTestimonialsButtom = ({ sect }) => {
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const tabsRef = useRef(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/testimonials/?populate=deep`
      )
      .then(({ data }) => {
        setData(data.data);
      });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        setActiveTab((prevTab) => (prevTab + 1) % data.length);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [data, isHovered]);

  return (
    <>
      <div
        className="rn-testimonial-area pb--60 pt--20"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title" style={{ fontSize: "50px" }}>
                  What Our Clients Say
                </h2>
                <p>This is What We Live For</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Tabs
                selectedIndex={activeTab}
                onSelect={(index) => setActiveTab(index)}
              >
                {data.map((value, index) => (
                  <TabPanel key={index}>
                    <div className="rn-testimonial-content text-center">
                      <div className="inner">
                        <p>{value.attributes.testimonial}</p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>{value.attributes.testimonialName}</span>
                          {value.attributes.testimonialTag}
                        </h6>
                      </div>
                    </div>
                  </TabPanel>
                ))}

                <TabList className="testimonial-thumb-wrapper" ref={tabsRef}>
                  {data.map((value, index) => (
                    <Tab key={index}>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src={
                              `${process.env.REACT_APP_BACKEND_URL}` +
                              value.attributes.img.data.attributes.url
                            }
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                  ))}
                </TabList>
              </Tabs>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="view-more-btn mt--60 text-center">
                <HashLink
                  className="rn-button-style--20 btn-solid"
                  to="/contact-us/#contact-page-form"
                >
                  <span>Connect with Us Today</span>
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ServicesTestimonialsButtom;
