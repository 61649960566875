import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ScrollAnimation from "react-animate-on-scroll";

const CounterOne = ({ sect }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <div className="row mt--40">
      {sect?.Counter.map((value, index) => (
        <div className="counterup_style--2 col-lg-3 col-md-6 col-6" key={index}>
          <ScrollAnimation animateIn="fadeInUp" animateOnce={true} delay={100}>
            <h5 className="counter">
              <VisibilitySensor
                onChange={onVisibilityChange}
                offset={{ top: 10 }}
                delayedCall
              >
                <CountUp end={didViewCountUp ? value.countNum : 0} />
              </VisibilitySensor>
            </h5>
            <h4 className="description">{value.countTitle}</h4>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default CounterOne;
