import React from "react";
import { FiCheck } from "react-icons/fi";
import PopupCard from "../global/PopupCard";
import { HashLink } from "react-router-hash-link";

const Pricing = ({ sect }) => {
  return (
    <>
      <main className="page-wrapper">
        <div className="rn-pricing-table-area ptb--60 ">
          <div className="container">
            <div className="row">
              {sect.pricing.map((item) => (
                <div className="col-lg-4 col-md-6 col-12 pt--80" key={item.id}>
                  <div className={`rn-pricing ${item.active ? "active" : ""}`}>
                    <div className="pricing-table-inner ">
                      <div className="pricing-header">
                        <h4 className="title">{item.title}</h4>
                        <div className="pricing">
                          <span className="price">{item.price}</span>
                          <span className="subtitle">{item.subTitle}</span>
                        </div>
                      </div>
                      <div className="pricing-body">
                        <ul className="list-style--1">
                          {Object.values(item)
                            .slice(4)
                            .filter(
                              (value, index) =>
                                Object.keys(item)[index + 4] !== "active" &&
                                value !== null
                            )
                            .map((value, index) => (
                              <li
                                key={index}
                                className={index === 1 ? "with-margin" : ""}
                              >
                                {index < 2 ? null : <FiCheck />} {value}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="pricing-footer">
                        <HashLink
                          className="rn-btn"
                          to="/contact-us/#contact-page-form"
                        >
                          Get Started
                        </HashLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      <PopupCard />
    </>
  );
};

export default Pricing;
