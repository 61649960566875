import React from "react";
import GoogleMapReact from "google-map-react";
import { FaMapMarkerAlt } from "react-icons/fa"; // Assuming you're using Font Awesome for the marker icon

const AnyReactComponent = ({ text }) => (
  <div>
    <FaMapMarkerAlt style={{ color: "red", fontSize: "24px" }} />
    {text}
  </div>
);

const ContactMap = ({ sect }) => {
  const defaultCenter = {
    lat: 52.6361535,
    lng: -1.1249858,
  };
  const defaultZoom = 15;

  return (
    <div className="rn-contact-map-area position-relative">
      <div style={{ height: "650px", width: "100%" }}>
        <GoogleMapReact
          defaultCenter={defaultCenter}
          defaultZoom={defaultZoom}
          bootstrapURLKeys={{
            key: "AIzaSyCJKxBulqVmif4-6qvYul3rY5IXI4Yb0y8",
          }}
        >
          <AnyReactComponent
            lat={52.6361535}
            lng={-1.1249858}
            //text={sect.text}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default ContactMap;
