import React from "react";
import Loader from "react-loader-spinner/dist/loader/TailSpin";
import "./Loaders.css";

const Loaders = () => {
  return (
    <div className="loader-container">
      <Loader
        type="TailSpin"
        color="#000000"
        height={70}
        width={70}
        timeout={5000}
      />
    </div>
  );
};

export default Loaders;
