import React, { useRef, useEffect } from "react";
import "./Clutch.scss";
import { HashLink } from "react-router-hash-link";

const Clutch = ({ sect }) => {

  const ref = useRef();

  useEffect(() => {
    const element = ref.current;

    if (element instanceof Node) {
      const widgetElement = document.querySelector(".clutch-widget");

      if (widgetElement instanceof Node) {
        element.appendChild(widgetElement);
      } else {
        console.error("Widget element not found");
      }
    } else {
      console.error("Element not found");
    }
  }, []);

  return (
    <>
      <div ref={ref} className="clutch-review-section"></div>
      <div className="row">
        <div className="col-lg-12">
          <div className="view-more-btn mt--60 text-center">
            <HashLink
              className="rn-button-style--20 btn-solid"
              to="/contact-us/#contact-page-form"
            >
              <span>{sect.ctaButtontext}</span>
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clutch;
