import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import LazyLoad from "react-lazyload";

const ServicesTwo = ({ sect }) => {
  return (
    <>
      <LazyLoad height={200} once>
        <div className="service-area pt--120 pb--50 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center service-style--3 mb--30">
                  <ScrollAnimation animateIn="fadeIn">
                    <h2 className="title">{sect.title}</h2>
                    <p>{sect.description}</p>
                  </ScrollAnimation>
                </div>
              </div>
            </div>

            <div className="row">
              {sect.serviceCard.map((val, i) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 text-center  d-flex align-items-stretch fade-in-text"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="content">
                      <ScrollAnimation animateIn="fadeIn">
                        <h3 className="title">{val.title}</h3>
                        <p>{val.description}</p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </LazyLoad>
    </>
  );
};

export default ServicesTwo;
