import React, { useState, useEffect } from "react";
import "./PopupCard.scss";
import emailjs from "emailjs-com";
import { useLocation, useHistory } from "react-router-dom";

const PopupCard = () => {
  const [isVisible, setIsVisible] = useState(false);
/*   const [popupDisplayed, setPopupDisplayed] = useState(false);
 */  const history = useHistory();

  useEffect(() => {
    const maintenancePages = ["website-maintenance"];

    const isWebsiteMaintenancePage = maintenancePages.some((substring) =>
      window.location.href.includes(substring)
    );

    const lastPopupTime = localStorage.getItem("lastPopupTime");
    const currentTime = new Date().getTime();
    const backoffTimeDuration = 1;
    const backoffTimeUnit = "DAYS";

    if (
      isWebsiteMaintenancePage &&
      (!lastPopupTime ||
        currentTime - parseInt(lastPopupTime) >=
          backoffTimeDuration * getMilliseconds(backoffTimeUnit))
    ) {
      const handleMouseLeave = (e) => {
        if (e.clientY <= 0 && !isVisible) {
          setIsVisible(true);
          localStorage.setItem("lastPopupTime", currentTime);
        }
      };

      document.body.addEventListener("mouseleave", handleMouseLeave);

      return () => {
        document.body.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [isVisible]);

   

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleBackdropClick = () => {
    setIsVisible(false);
  };

  const handleCardClick = (e) => {
    e.stopPropagation();
  };

  const location = useLocation();
  const [currentPageSlug, setCurrentPageSlug] = useState("");

  useEffect(() => {
    const pathArray = location.pathname;
    setCurrentPageSlug(pathArray);
  }, [location]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fjk1njd",
        "template_54yuk4m",
        e.target,
        "BX0WtIBtVDQ5s29L9"
      )
      .then(
        () => {
          history.push("/thankyou");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  const getMilliseconds = (unit) => {
    switch (unit) {
      case "MILLISECONDS":
        return 1;
      case "SECONDS":
        return 1000;
      case "MINUTES":
        return 1000 * 60;
      case "HOURS":
        return 1000 * 60 * 60;
      case "DAYS":
        return 1000 * 60 * 60 * 24;
      case "WEEKS":
        return 1000 * 60 * 60 * 24 * 7;
      case "MONTHS":
        return 1000 * 60 * 60 * 24 * 30;
      case "YEARS":
        return 1000 * 60 * 60 * 24 * 365;
      default:
        return 1;
    }
  };

  return (
    <div
      className={`popup-card-container ${isVisible ? "show" : ""}`}
      onClick={handleBackdropClick}
    >
      <div className="popup-card" onClick={handleCardClick}>
        <button className="close-button" onClick={handleClose}>
          X
        </button>
        <div className="card-top">Before You Go ...</div>
        <div className="card-middle">Have a free month of support</div>
        <div className="card-bottom">One month of our Standard plan</div>
        <div className="rn-contact-page">
          <div className="contact-form--1">
            <div className="container">
              <div className="row  align-items-start">
                <div className="col-lg-12 order-lg-2 ptb--20 margin">
                  <div className="form-wrapper">
                    <form action="" onSubmit={sendEmail}>
                      <div className="rn-form-group">
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Your Name"
                          required
                        />
                      </div>

                      <div className="rn-form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Your E-mail"
                          required
                        />
                      </div>

                      <div className="rn-form-group-button">
                        <button
                          className="rn-button-style-popcard--2 btn-solid"
                          type="submit"
                          value="submit"
                          name="submit"
                          id="mc-embedded-subscribe"
                        >
                          Yes, I Want 1 Free Month!
                        </button>
                      </div>

                      <div className="rn-form-group">
                        <input
                          type="hidden"
                          name="pageSlug"
                          value={currentPageSlug}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupCard;
