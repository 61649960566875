import React, { useMemo } from "react";
import "./topbar.scss";

const Topbar = () => {
  const email = useMemo(() => "joe@omtechmasters.co.uk", []);
  const phoneNumber = useMemo(() => "0116 393 0838", []);

  return (
    <div id="top" className="top-review-bar">
      <div className="d-flex align-items-center justify-content-between">
        <div className="top-left-part">
          <a href={`${process.env.PUBLIC_URL}/clutch-review/`}>
            <img
              src="/assets/images/clutch/clutch-b2b-logo.svg"
              alt="clutch"
              width={88}
              height={25}
            />
            <div className="star">
              <img
                src="/assets/images/clutch/footer-rating.png"
                alt="star"
                width={110}
                height={19}
              />
            </div>
            <strong>Excellent</strong>
            <span> 5 out of 5</span>
          </a>
        </div>
        <div className="top-right-part d-flex align-items-center justify-content-between1">
          <span>
            Email us on{" "}
            <a href={`mailto:${email}`}>
              <strong>{email}</strong>
            </a>{" "}
            or Speak to our expert
          </span>
          &nbsp;
          <a href={`tel:${phoneNumber}`}>
            <strong>{phoneNumber}</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
