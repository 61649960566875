import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import { Link } from "react-router-dom";

import { Remarkable } from "remarkable";
import { HashLink } from "react-router-hash-link";

const PortfolioDetails = () => {
  const [md, setMd] = useState(new Remarkable());

  const location = useLocation();

  const slugFromRoute = location.pathname.replace("/portfolio-details/", "");

  const [data, setData] = useState([]);
  const [project, setProject] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/projects/?populate=deep`)
      .then(({ data }) => {
        setData(data.data);

        const matchingProject = data.data.find(
          (project) => project.attributes.slug === slugFromRoute
        );
        setProject(matchingProject);
      });
  }, [slugFromRoute]);

  const { platform, projectDuration, websiteUrl } = project?.attributes || {};

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const renderedContent = md.render(project?.attributes?.RichtextD || "");
  const renderedContent2 = md.render(project?.attributes?.RichtextD2 || "");
  const renderedContent3 = md.render(project?.attributes?.RichtextD3 || "");

  return (
    <>
      <div className="rn-portfolio-details ptb--80 bg_color--1">
        <div className="container" style={{ maxWidth: "1680px" }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="portfolio-details">
                <div className="inner">
                  <div dangerouslySetInnerHTML={{ __html: renderedContent }} />
                  <div className="portfolio-view-list d-flex flex-wrap">
                    <div className="port-view">
                      <span>Platform</span>
                      <h4>{platform}</h4>
                    </div>

                    <div className="port-view">
                      <span>Project Duration</span>
                      <h4>{projectDuration}</h4>
                    </div>

                    <div className="port-view">
                      <span>Project URL</span>
                      <a
                        href={websiteUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h4>{websiteUrl}</h4>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="rn-contact-page ptb--80 bg_color--5"
        style={{ backgroundColor: "#DCDCDC" }}
      >
        <div className="contact-form--1">
          <div className="container" style={{ maxWidth: "1400px" }}>
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-titletwo text-left  ">
                  <p className="description">
                    <h5>Business Requirements</h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: renderedContent2 }}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2  ">
                <div className="thumbnail mb_md--30 mb_sm--30 percentagepad">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}${project?.attributes?.img1?.data?.attributes?.url}`}
                    alt="Portfolio Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="rn-contact-page ptb--80 bg_color--5">
        <div className="contact-form--1">
          <div className="container" style={{ maxWidth: "1400px" }}>
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left  ">
                  <h5 className="title">The Results</h5>
                  <p className="description">
                    <div
                      dangerouslySetInnerHTML={{ __html: renderedContent3 }}
                    />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2  ">
                <div className="thumbnail mb_md--30 mb_sm--30">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}${project?.attributes?.img2?.data?.attributes?.url}`}
                    alt="Portfolio Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portfolio-thumb-inner ptb--80">
        <div className="thumb ">
          <img
            src={`${process.env.REACT_APP_BACKEND_URL}${project?.attributes?.img3?.data?.attributes?.url}`}
            alt="Portfolio Images"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 ">
          <div className="view-more-btn text-center">
            <HashLink
              className="rn-button-style--2 btn-solid"
              to="/contact-us/#contact-page-form"
            >
              <span>Book a free consultation</span>
            </HashLink>
          </div>
        </div>
      </div>

      <div className="portfolio-area ptb--20 bg_color--1 mt--60  mt_sm--40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center service-style--3 mb--30">
                <h2 className="title">Our Projects</h2>
                <p>WE BELIEVE OUR WORK SPEAKS FOR ITSELF</p>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper portfolio-sacousel-inner mb--55">
          <div className="portfolio-slick-activation mt--70 mt_sm--40 portfolio-detail">
            <Slider {...settings} {...portfolioSlick2}>
              {data.map((value, index) => (
                <Link
                  to={{
                    pathname: `/portfolio-details/${value.attributes.slug}`,
                    state: {
                      projectTitle: value.attributes.projectTitle,
                      projectDes: value.attributes.projectDes,
                      platform: value.attributes.platform,
                      projectDuration: value.attributes.projectDuration,
                      websiteUrl: value.attributes.websiteUrl,
                      imgUrl: value.attributes.portfolioImg.data.attributes.url,
                      headerImg: value.attributes.headerImg.data.attributes.url,
                      name: value.attributes.name,
                    },
                  }}
                >
                  <div className="portfolio" key={index}>
                    <div className="thumbnail-inner">
                      <div
                        className="thumbnail"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_BACKEND_URL +
                            value.attributes.Img.data[0].attributes.url
                          })`,
                        }}
                      ></div>

                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <p>{value.attributes.category}</p>
                        <h5>
                          <Link
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            {value.attributes.title}
                          </Link>
                        </h5>

                        <div className="portfolio-button">
                          <Link
                            className="rn-btn"
                            to={{
                              pathname: `/portfolio-details/${value.attributes.slug}`,
                              state: {
                                projectTitle: value.attributes.projectTitle,
                                projectDes: value.attributes.projectDes,
                                platform: value.attributes.platform,
                                projectDuration:
                                  value.attributes.projectDuration,
                                websiteUrl: value.attributes.websiteUrl,
                                imgUrl:
                                  value.attributes.portfolioImg.data.attributes
                                    .url,
                                headerImg:
                                  value.attributes.headerImg.data.attributes
                                    .url,
                                name: value.attributes.name,
                              },
                            }}
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                    <Link
                      className="link-overlay"
                      to={{
                        pathname: `/portfolio-details/${value.attributes.slug}`,
                        state: {
                          projectTitle: value.attributes.projectTitle,
                          projectDes: value.attributes.projectDes,
                          platform: value.attributes.platform,
                          projectDuration: value.attributes.projectDuration,
                          websiteUrl: value.attributes.websiteUrl,
                          imgUrl:
                            value.attributes.portfolioImg.data.attributes.url,
                          headerImg:
                            value.attributes.headerImg.data.attributes.url,
                          name: value.attributes.name,
                        },
                      }}
                    ></Link>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioDetails;
