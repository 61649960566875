import React from "react";
import { Link } from "react-router-dom";
import Topbar from "../global/Topbar";
/* import GlobalPopupCard from "../global/GlobalPopupCard";
 */
const Header = ({ sect }) => {
  return (
    <React.Fragment>
      <Topbar />
{/*       <GlobalPopupCard />
 */}      <div
        className="breadcrumb-area rn-bg-color ptb--120 bg_image "
        style={{
          backgroundImage: `url(${
            process.env.REACT_APP_BACKEND_URL + sect.img.data.attributes.url
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner pt--100">
                <h2 className="title">{sect.title}</h2>
                <ul className="page-list">
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}`}>
                      {sect.subtitleOne}
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">{sect.subtitleTwo}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
