import Topbar from "../global/Topbar";
import { Link } from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import emailjs from "emailjs-com";
import NarrowClutch from "../global/NarrowClutch";
/* import GlobalPopupCard from "../global/GlobalPopupCard";
 */
const ServicesContactForm = ({ sect }) => {
  const ref = useRef();
  const history = useHistory();

  useEffect(() => {
    const element = ref.current;

    if (element instanceof Node) {
      const widgetElement = document.querySelector(".cluthtwo");

      if (
        widgetElement instanceof Node &&
        widgetElement !== element &&
        !element.contains(widgetElement)
      ) {
        element.appendChild(widgetElement);
      } else {
        console.error(
          "Widget element not found or attempting to append to itself"
        );
      }
    } else {
      console.error("Element not found");
    }
  }, [ref]);

  const location = useLocation();
  const [currentPageSlug, setCurrentPageSlug] = useState("");

  useEffect(() => {
    const pathArray = location.pathname;

    setCurrentPageSlug(pathArray);
  }, [location]);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_fjk1njd",
        "template_54yuk4m",
        e.target,
        "BX0WtIBtVDQ5s29L9"
      )
      .then(
        () => {
          history.push("/thankyou");
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <>
      
      <Topbar />

      <div className="slider-wrapper pb--100">
        <div className="slider-activation ">
          {sect.sliderOne.map((value, index) => (
            <div key={index}>
              <div
                className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center `}
                style={{
                  color: "white",
                  backgroundPosition: " center ",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${
                    process.env.REACT_APP_BACKEND_URL +
                    value.bgImage.data.attributes.url
                  })`,
                }}
                key={value.id}
                data-black-overlay="5"
              >
                <div className="mobile">
                  <div className="container ">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="breadcrumb-inner pt--100">
                          <h2 className="title">{value.buttonText}</h2>
                          <ul className="page-list">
                            <li className="breadcrumb-item">
                              <Link to={`${process.env.PUBLIC_URL}`}>
                                {value.buttontxt2}
                              </Link>
                            </li>

                            <li className="breadcrumb-item active">
                              {value.buttonText}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rn-contact-page nowview ">
                  <div className="contact-form--1">
                    <div className="container ">
                      <div className="row row--35 align-items-start">
                        <div className="col-lg-6 col-12 order-2 order-lg-1">
                          <div className="details mt_md--30 mt_sm--30 pb--80">
                            <h1 className="title " style={{ color: "white" }}>
                              {value.textPosition}
                            </h1>
                            <p>{value.category}</p>
                            <p>{value.title}</p>
                            <p>{value.description}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 pt_md--40">
                          <div className="section-title text-left mb--20">
                            <p
                              className="description"
                              style={{ color: "white" }}
                            >
                              ENQUIRE FOR A
                            </p>
                            <h3 className="title" style={{ color: "white" }}>
                              FREE QUOTATION
                            </h3>
                          </div>
                          <div className="form-wrapper">
                            <form
                              action=""
                              onSubmit={sendEmail}
                              className="custom-form"
                            >
                              <div className="rn-form-group">
                                <input
                                  type="text"
                                  name="fullname"
                                  placeholder="Your Name"
                                  required
                                  className="white-background-input"
                                />
                              </div>

                              <div className="rn-form-group">
                                <input
                                  type="email"
                                  name="email"
                                  placeholder="Your E-mail"
                                  required
                                  className="white-background-input"
                                />
                              </div>

                              <div className="rn-form-group">
                                <input
                                  type="text"
                                  name="phone"
                                  placeholder="Your Phone"
                                  className="white-background-input"
                                />
                              </div>

                              <div className="rn-form-group">
                                <textarea
                                  name="message"
                                  placeholder="How can we help?"
                                  className="white-background-input"
                                ></textarea>
                              </div>

                              <div className="rn-form-group">
                                <button
                                  className="rn-button-style--2 btn-solid"
                                  type="submit"
                                  value="submit"
                                  name="submit"
                                  id="mc-embedded-subscribe"
                                >
                                  GET A QUOTE NOW !
                                </button>
                              </div>

                              <div className="rn-form-group">
                                <input
                                  type="hidden"
                                  name="pageSlug"
                                  value={currentPageSlug}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <NarrowClutch />
    </>
  );
};

export default ServicesContactForm;
